import React, { useState, useContext, useEffect } from "react"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import LinearProgress from "@material-ui/core/LinearProgress"
import useStyles from "./styles"
import { useEnv } from "@Hooks"
import { GlobalContext } from "@Context/context/GlobalState"
import { Autocomplete } from "@material-ui/lab"
import _translate from "@Translates/"
import { getReport, getTemplate, deleteReportFile } from "@Services/downloadService"
import TableReportDownload from "./DownloadReportTable"
import Filters from "./Filters"
// import { filtersDetails, options } from "./Filters/filtersInfo"
import Swal from "sweetalert2"
import { addElementDb, updateElementInDb, deleteElementInDb, getAllElementsInDb } from "@Hooks/useDB"
import rsc from "@Config/resources"
import { values } from "@Methods/LoadFilterByResourceId/reports"
import getOptionsFilterSources from "@Methods/LoadFilterByResourceId/LoadFilterByResourceId"

/*
 * Build the main view of Downloable Reports
 * This view use IndexedDB to store in the browser a list of reports and his current state ( Available or not )
 *
 */
import { useTranslation } from "react-i18next"
const DownloadReports = () => {
  const use_env = useEnv()
  const user = use_env.multiclient.get_user()
  let [, _env] = use_env.multiclient.get_user_env()
  const { handleErrorSession } = useContext(GlobalContext)
  const [report, setReport] = useState(null)
  const [reportList, setReportList] = useState([])
  const [load, setLoad] = useState(false)
  const [input, setInput] = useState("")
  const [value, setValue] = useState([null, null])
  const [channels, setChannels] = useState([])
  const classes = useStyles()
  const [disable, setDisable] = useState(true)
  const [resetAuto, setResetAuto] = useState(false)
  const [reportFilter, setReportFilter] = useState([])
  const [loadTable, setLoadTable] = useState(true)
  const [permission, set_permission] = useState({})
  const { multiclient } = useEnv()
  const { t } = useTranslation("global")
  const columns = ["", t("report-name"), t("query-date"), t("initial-date"), t("final-date"), t("actions")]

  useEffect(() => {
    const _allowed = multiclient.get_permission_list_state(rsc.REPORTS, [
      rsc.ReportOrder,
      rsc.REPORT_OPTION_ORDERS,
      rsc.REPORT_OPTION_ORDERS_V2,
      rsc.ReportOrderDetail,
      rsc.REPORT_OPTION_ITEM_ORDER,
      rsc.Disponibility,
      rsc.REPORT_OPTION_AVAILABILITY,
      rsc.OrderReserve,
      rsc.REPORT_OPTION_ORDERS_IN_RESERVE,
      rsc.RequestMs,
      rsc.REPORT_OPTION_OUTPUT_OF_SERVICE_REQUEST,
      rsc.Transactions,
      rsc.REPORT_OPTION_TRANSACTIONS,
      rsc.ReportOperation,
      rsc.REPORT_OPTION_ORDER_OPERATION,
      rsc.ReportItems,
      rsc.REPORT_OPTION_ITEMS,
      rsc.ReportCapacity,
      rsc.REPORT_OPTION_CAPACITY,
    ])

    set_permission(_allowed)

    getIndexedDBRecords()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getIndexedDBRecords = async () => {
    const test = await getAllElementsInDb()
    const filteredArray = test.reverse().filter(({ ownerId }) => ownerId === user?.id)
    setReportList([...filteredArray])
    setTimeout(function () {
      setLoadTable(false)
    }, 2000)
  }

  useEffect(() => {
    if (report === null) {
      setReportFilter([])
      setDisable(true)
    } else {
      // const element = filtersDetails.find(({ id }) => {
      const element = values.find(({ id }) => {
        return id === report
      })
      setReportFilter(element.filterType)
      setDisable(false)
    }
  }, [report])

  const cleanSelection = (complete = true) => {
    setValue([null, null])
    setChannels([])
    if (complete === true) {
      setReport(null)
      setInput("")
    }
    setResetAuto(!resetAuto)
  }

  const buildReport = () => {
    getDownloadUrl()
  }

  //This function craft the range object in the format that backend use (The range object is use in some reports to handle how much information will be provided)
  const getDateRange = (value) => {
    if (value[0] === null && value[1] === null) {
      return null
    }
    if (value[0] !== null && value[1] === null) {
      return {
        from: buildDateToBack(value[0], "00:00:00Z"),
        to: buildDateToBack(value[0], "23:59:59Z"),
      }
    }
    if (value[0] !== null && value[1] !== null) {
      return {
        from: buildDateToBack(value[0], "00:00:00Z"),
        to: buildDateToBack(value[1], "23:59:59Z"),
      }
    }
  }

  //This function craft the range object with a readble for display it in the table

  const getDateRangeDisplayTable = (value) => {
    if (value[0] === null && value[1] === null) {
      return null
    }
    if (value[0] !== null && value[1] === null) {
      return {
        from: buildDateAbsoluteTable(value[0]),
        to: buildDateAbsoluteTable(value[0]),
      }
    }
    if (value[0] !== null && value[1] !== null) {
      return {
        from: buildDateAbsoluteTable(value[0]),
        to: buildDateAbsoluteTable(value[1]),
      }
    }
  }
  //This function transform a date in a more readable format to be display in the table DD/MM/YYY

  const buildDateAbsoluteTable = (date) => {
    const day = ("0" + date.getDate()).slice(-2)
    const month = ("0" + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }

  //This function transforms a date object in the mandatory format needed in BackEnd

  const buildDateToBack = (date, time) => {
    const day = ("0" + date.getDate()).slice(-2)
    const month = ("0" + (date.getMonth() + 1)).slice(-2)
    const year = date.getFullYear()
    return `${year}-${month}-${day}T${time}`
  }

  const getDownloadUrl = async () => {
    try {
      setLoad(true)
      const env = _env
      console.log("Enviroment DownLoad", env)
      const date = getDateRange(value)
      const _report = report === "orders_test_v2" ? "orders_test" : report
      const response = await getTemplate(getToken(env), _report)
      let checkIfRangeIsAvailable = null
      let { _id, ...allInformation } = response.data.message
      let jsonFull
      if (date !== null) {
        const [params, haveRange] = editParamsRange(allInformation.params, date)
        jsonFull = {
          ...allInformation,
          params: params,
        }
        checkIfRangeIsAvailable = haveRange
      } else {
        jsonFull = { ...allInformation }
        if (input === "Disponibilidad") {
          jsonFull = addChannelsInAvailabilityReport(allInformation)
          setChannels([])
        }
      }

      const report_parsed = report === "orders_test_v2" ? "orders" : report
      console.log("report_parsed", report_parsed)

      const linkReport = await getReport(getToken(env), jsonFull, report_parsed)
      const { fileName, link } = linkReport.data.message
      const reportDate = checkIfRangeIsAvailable ? getDateRangeDisplayTable(value) : null

      buildTableToList(report_parsed, reportDate, link, fileName)
      cleanSelection(false)
      setLoad(false)
    } catch (error) {
      if (error.response.status === 404) {
      } else {
        handleErrorSession({
          error: true,
          errorCode: error.response.status,
          messageError: error?.response?.data?.message,
        })
      }
    }
  }

  // Add channels to availability report
  const addChannelsInAvailabilityReport = (info) => {
    let data = { ...info }
    data.params = (info.params || []).map((val) => {
      if (val?.entity === "channel" && val?.path === "id") {
        return { ...val, values: channels }
      }
      return val
    })
    return data
  }

  // Search a field with the property range to edit his object
  const editParamsRange = (array, date) => {
    let range = false
    const newArray = array.map((val) => {
      if ("range" in val) {
        range = true
        return { ...val, range: { ...date } }
      } else {
        return { ...val }
      }
    })

    return [newArray, range]
  }

  // For the information display on the table and storage in indexedDb this create a "Schema" of the object
  const buildTableToList = (name, date, link, filename, available = false) => {
    const initDate = date !== null ? date.from : "N/A"
    const finalDate = date !== null ? date.to : "N/A"
    const objectTable = {
      creationDate: new Date().getTime(),
      reportId: name,
      name: t(_translate(name)),
      initDate: initDate,
      finalDate: finalDate,
      link: link,
      filename: filename,
      available: available,
      ownerId: user?.id,
    }
    setReportList([objectTable, ...reportList])
    addElementDb(objectTable)
  }

  // This application use a differents token , so we need a fuction to extract this for the enviroments variables
  const getToken = (env) => {
    return user?.token
  }

  // Init Fuctions to handle the state of the reports (Availability , delete report )
  const deleteReport = (reportId, fileName, creationDate) => {
    const tittle = `${t("are-you-sure-to-delete")} ${t(_translate(reportId))} ?`
    Swal.fire({
      title: tittle,
      text: `${t("file-name")} : ${fileName}`,
      icon: "question",
      showDenyButton: true,
      confirmButtonText: t("delete"),
      denyButtonText: t("cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        sendInformationToDelete(reportId, fileName, creationDate)
      }
    })
  }

  const changeStateToDelete = (creationDateDelete) => {
    const newArray = reportList.filter(({ creationDate }) => creationDate !== creationDateDelete)
    setReportList([...newArray])
    deleteElementInDb(creationDateDelete)
  }

  const setAvailable = (timestampSearch) => {
    const index = reportList.findIndex(({ creationDate }) => creationDate === timestampSearch)
    const element = { ...reportList[index] }
    const elementEdited = { ...element, available: true }
    let copyArray = reportList
    copyArray[index] = elementEdited
    updateElementInDb(elementEdited)
  }

  const sendInformationToDelete = async (reportId, fileName, creationDate) => {
    const env = _env
    console.log("Enviroment DownLoad", env)

    setLoad(true)
    try {
      await deleteReportFile(getToken(env), reportId, fileName)
      changeStateToDelete(creationDate)
      Swal.fire(t("report-removed-successfully"), "", "success")
      setLoad(false)
    } catch (error) {
      setLoad(false)
      Swal.fire("Error inesperado", t("an-error-occurred-in-the-removal-process"), "error")
    }
  }
  // End Fuctions to handle the state of the reports (Availability ,  delete report )

  return (
    <Paper className={classes.mainContainer}>
      <Typography variant="h6">{t("report-selection")}</Typography>
      <Autocomplete
        key={resetAuto}
        fullWidth
        id="selector-reports"
        inputValue={input}
        onInputChange={(e, newValue) => setInput(newValue)}
        onChange={(e, newValue) => setReport(newValue)}
        // options={options}
        options={getOptionsFilterSources(permission, values)}
        clearOnBlur
        getOptionLabel={(option) => t(_translate(option))}
        getOptionSelected={(option, value) => t(_translate(option)) === value}
        renderInput={(params) => <TextField {...params} fullWidth label={t("report-list")} variant="outlined" />}
      />
      <Filters filters={reportFilter} value={value} setValue={setValue} channels={channels} setChannels={setChannels} />
      <div
        key={"button-container"}
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          gap: 10,
          marginTop: 10,
        }}
      >
        <Button color="primary" variant="contained" disabled={disable || load} onClick={() => buildReport()}>
          {t("generate-report")}
        </Button>
        <Button color="secondary" variant="outlined" disabled={load} onClick={() => cleanSelection()}>
          {t("clean-selection")}
        </Button>
      </div>

      {load && <LinearProgress style={{ marginTop: 10, marginBottom: 10 }} />}
      <>
        <TableReportDownload
          columns={columns}
          info={reportList}
          deleteReport={deleteReport}
          setAvailable={setAvailable}
          loadContent={loadTable}
        />
      </>
    </Paper>
  )
}

export default DownloadReports
